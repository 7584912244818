export interface IModalWithClose {
  handleClose?: () => void;
}

export type ModalComponentType = (React.MemoExoticComponent<(
  { handleClose }: { handleClose: () => void }
) => JSX.Element | null>) | undefined

export const enum ContextModalType {
  verifiedPhone = 'verifiedPhone',
}
