/* eslint-disable max-classes-per-file */
import { t } from 'i18next'
import { toast } from 'react-toastify'

import { NO_DATA } from 'src/constants/error.constants'

import { captureException } from './browser.utils'

// Note: this is the base for any custom error. CustomError can't be used to throw
class CustomError extends Error {
  constructor(errorCode: string, cause?: Error) {
    super(errorCode)

    if (cause) {
      this.cause = cause
    }

    this.name = this.constructor.name
  }
}

export class SubmitFormError extends CustomError {
  errorObject: Record<string, string>

  constructor(errorCode: string, errorObject: Record<string, string>, cause?: Error) {
    super(errorCode, cause)

    this.errorObject = errorObject
  }
}

export class InternalServerError extends CustomError {}

export class AuthorizationError extends CustomError {}

export class NotFoundError extends CustomError {}

export class NotAcceptableError extends CustomError {}

export class BadRequestError extends CustomError {}

export class UnexpectedError extends CustomError {}

export class PostDeletedError extends CustomError {}

export class AssertDataError extends CustomError {}

export class SocketConnectionError extends CustomError {}
export class SocketErrorResponse extends CustomError {}

export class RouteParamsError extends CustomError {}

export class TooManyRequestsError extends CustomError {}

export class ForbiddenError extends CustomError {}

export function assertData<T>(data: T | undefined): asserts data is NonNullable<T> {
  if (!data) {
    throw new AssertDataError(NO_DATA)
  }
}

export const returnFormErrorsOrThrow = (error: any) => {
  if (error instanceof SubmitFormError) {
    return error.errorObject
  }

  throw error
}

export const captureErrorAndShowToast = (error: unknown) => {
  if (error instanceof AuthorizationError) {
    return // do not show toast in case of 401 error
  }

  if (error instanceof Error) {
    captureException(error)
    toast.error(t(error.message))
  }
}

export const returnFormErrorsOrShowToast = (error: unknown) => {
  if (error instanceof SubmitFormError) {
    return error.errorObject
  }

  return captureErrorAndShowToast(error)
}
