import classNames from 'classnames'
import { PropsWithChildren, ReactNode } from 'react'

import IconButton from 'src/components/button/IconButton'
import Heading from 'src/components/heading/Heading'
import SvgIcon from 'src/components/svgIcon/SvgIcon'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'

import styles from './infoModal.module.scss'

interface IInfoModalProps {
  handleClose?: () => void;
  modalFooter?: ReactNode;
  title?: string;
  subtitle?: string;
  topIcon?: string;
  className?: string;
}

const InfoModal = ({
  children,
  title,
  topIcon,
  subtitle,
  className,
  modalFooter,
  handleClose,
}: PropsWithChildren<IInfoModalProps>) => {
  const { isDesktop } = useViewport()
  const showCloseButton = handleClose && !isDesktop

  return (
    <div className={classNames(styles.container, className)}>
      {(topIcon || title || subtitle || showCloseButton) && (
        <div className={styles.headerWithClose}>
          <div className={styles.headerBlock}>
            { topIcon && <SvgIcon name={topIcon} className={styles.topIcon} size={32}/>}
            <div className={styles.title}>
              { title && <Heading label={title} level='h2' className='desktop-h5 mobile-h4' /> }
              {subtitle && (
                <p className='label-18'>
                  {subtitle}
                </p>
              )}
            </div>
          </div>
          {showCloseButton && (
            <IconButton iconName='close-cross' size={24} onClick={handleClose} id={ID.modalCloseButton}/>
          )}
        </div>
      )}
      <div className={styles.scrollBody}>
        { children }
      </div>
      { modalFooter && (
        <div className={styles.footer}>
          { modalFooter }
        </div>
      )}
    </div>
  )
}

export default InfoModal
