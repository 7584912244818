import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import ReportModal from 'src/components/modal/reportModal/ReportModal'
import { I18N_POST, I18N_POST_SUPPORTIVE } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { IPostStatistic } from 'src/models/post.model'
import { useDisplayDate } from 'src/utils/date.utils'

import styles from './supportiveInfo.module.scss'

interface IPostSupportiveInfoProps {
  placedAt: string;
  statistic: IPostStatistic;
  expiredAt: string;
  postId: string;
}

const REPORT_QUERY_NAME = 'report'

const PostSupportiveInfo = ({ placedAt, statistic, expiredAt, postId } : IPostSupportiveInfoProps) => {
  const { t } = useTranslation()
  const { isDesktop } = useViewport()
  const displayDate = useDisplayDate()

  const [searchParams, setSearchParams] = useSearchParams()
  const isReportModalOpen = searchParams.has(REPORT_QUERY_NAME)

  const onReportClick = useCallback(() => {
    setSearchParams({ [REPORT_QUERY_NAME]: '1' })
  }, [setSearchParams])

  const handleModalClose = useCallback(() => {
    setSearchParams()
  }, [setSearchParams])

  const publishedDate = displayDate(placedAt)
  const expiresDate = displayDate(expiredAt)

  const infoItems = [
    {
      key: 'placed',
      value: publishedDate,
    },
    {
      key: 'views',
      value: statistic.viewsNumber,
    },
    {
      key: 'likes',
      value: statistic.likesNumber,
    },
    {
      key: 'expires',
      value: expiresDate,
    },
  ]

  return (
    <div className={styles.main}>
      <ul className={styles.list}>
        {infoItems.map((item) => (
          <li key={item.key}>
            {item.key === 'views' || item.key === 'likes' ? `${item.value} ` : ''}
            {t(`${I18N_POST_SUPPORTIVE}.${item.key}`)}
            {item.key === 'expires' || item.key === 'placed' ? ` ${item.value}` : ''}
          </li>
        ))
        }
      </ul>
      <RegularButton
        id={ID.postPageReportPost}
        buttonType={isDesktop ? RegularButtonTypes.transparentNoBorder : RegularButtonTypes.transparentWithBorder}
        onClick={onReportClick}
        label={`${I18N_POST}.report`}
        leftIcon={{ name: 'alert' }}
      />
      {isReportModalOpen && <ReportModal handleClose={handleModalClose} postId={postId}/>}
    </div>
  )
}

export default PostSupportiveInfo
