import classNames from 'classnames'
import { omit } from 'lodash-es'
import { Field, useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { useInputBlurOnValueUpdate } from 'src/hooks/form.hooks'

import { getFieldError } from '../../../utils/form.utils'
import FieldWrapper, { IFieldWrapperProps } from '../FieldWrapper'

import styles from './radio.module.scss'

interface IFormRadioBaseProps {
  id: string;
  name: string;
}

interface IFormRadioProps extends IFormRadioBaseProps {
  fieldWrapperProps?: IFieldWrapperProps;
  options: Record<string, string>;
  optionsInfo?: string[];
  className?: string;
}

interface IFormRadioInternalProps extends IFormRadioBaseProps {
  value: string;
  option: string;
}

const FormRadioInternal = ({ option, name, value }: IFormRadioInternalProps) => {
  const { t } = useTranslation()
  const label = t(option)

  return (
    <Field name={name} type="radio" value={value}>
      {({ input }) => (
        <>
          <input
            {...input}
            id={value}
            type="radio"
            title={label}
            className={styles.fieldInput}
          />
          <label htmlFor={value} className={classNames('body-text desktop-body-16', styles.label)}>
            {label}
          </label>
        </>
      )}
    </Field>
  )
}

export const FormRadio = ({
  id,
  name,
  options,
  className,
  optionsInfo,
  fieldWrapperProps,
}: IFormRadioProps) => {
  useInputBlurOnValueUpdate(name)
  const { meta } = useField(name)
  const error = getFieldError(meta)

  return (
    <fieldset className={classNames(styles.radioBtnContainer, !!error && styles.error, className)}>
      {Object.keys(options).map((key, ind) => (
        <FieldWrapper
          key={key}
          {...omit(fieldWrapperProps, 'tooltip')}
          name={key}
          info={optionsInfo ? optionsInfo[ind] : undefined}
          className={styles.fieldRadioBtn}
        >
          <FormRadioInternal id={id} name={name} option={options[key]} value={key} />
        </FieldWrapper>
      ))}
    </fieldset>
  )
}

export default FormRadio
