// global
export const I18N_GLOBAL = 'global'
export const I18N_GLOBAL_NOT_FOUND = `${I18N_GLOBAL}.notFound`

// Search page
export const I18N_SEARCH = 'search'
export const I18N_SEARCH_CITY = 'search.city'
export const I18N_SEARCH_FILTER = 'search.filterPanel'
export const I18N_SEARCH_ACTION_BAR = 'search.actionBar'

// Profile Settings page
export const I18N_PROFILE = 'profile'
export const I18N_PROFILE_ACTION = `${I18N_PROFILE}.action`
export const I18N_PROFILE_SETTING = `${I18N_PROFILE}.setting`
export const I18N_PROFILE_SETTING_PERSONAL = `${I18N_PROFILE}.setting.personalInfo`
export const I18N_PROFILE_SETTING_BTN = `${I18N_PROFILE_SETTING}.button`
export const I18N_PROFILE_SETTING_GENERAL = `${I18N_PROFILE_SETTING}.general`
export const I18N_PROFILE_SETTING_NOTIFICATIONS = `${I18N_PROFILE_SETTING}.notifications`
export const I18N_PROFILE_ITEMS = `${I18N_PROFILE}.items`

// Post page
export const I18N_POST = 'post'
export const I18N_POST_CHAT = `${I18N_POST}.chat`
export const I18N_POST_DATA = `${I18N_POST}.data`
export const I18N_POST_NOT_FOUND = `${I18N_POST}.notFound`
export const I18N_POST_ACTION = `${I18N_POST}.action`
export const I18N_POST_STATISTIC = `${I18N_POST_ACTION}.statistic`
export const I18N_POST_SUPPORTIVE = `${I18N_POST}.supportive`
export const I18N_POST_AUTO_RENEWAL = `${I18N_POST}.isAutoRenewal`

// messenger
export const I18N_MESSENGER = 'messenger'
export const I18_MESSENGER_FILTER = 'messenger.filter'

// header
export const I18N_HEADER = 'header'
export const I18N_HEADER_TABBAR = `${I18N_HEADER}.tabbar`

// footer
export const I18N_FOOTER = 'footer'
export const I18N_FOOTER_SECTION = 'footer.section'
export const I18N_FOOTER_LINK = 'footer.link'

// posts
export const I18N_POSTS = 'posts'
export const I18N_SORT = `${I18N_POSTS}.sort`

export const I18N_NOTHING_FOUND = 'nothingFound'
export const I18N_NOTHING_FOUND_MESSAGE = `${I18N_NOTHING_FOUND}.message`

// user
export const I18N_USER = 'user'
export const I18N_USER_NO_OFFERS = `${I18N_USER}.noOffers`
export const I18N_USER_HIDE_OFFERS = `${I18N_USER}.hideOffers`

export const I18N_HELP_FORM = 'helpForm'
export const I18N_HELP_FORM_SUCCESS = `${I18N_HELP_FORM}.success`
export const I18N_HELP_FORM_ERROR = `${I18N_HELP_FORM}.error`

// my offers
export const I18N_MY_OFFER_CARD = 'myOffer.card'
export const I18N_MY_OFFER_LIST = 'myOffer.list'

// favorites
export const I18N_FAVORITES = `${I18N_PROFILE}.favorite`

// categories
export const I18N_CATEGORIES = 'categories'

export const I18N_CATEGORY_CARD = 'categoryCard'

// error
export const INTERNAL_SERVER_ERROR_CODE = `${I18N_GLOBAL}.serverError`
export const I18N_SMTH_WENT_WRONG = `${I18N_GLOBAL}.somethingWentWrong`

// auth
export const I18N_AUTH = 'auth'

// Search location modal
export const I18N_CITY_MODAL = 'cityModal'

// cookie modal
export const I18N_COOKIE_MODAL = 'cookieModal'
// cookie manage modal
export const I18N_COOKIE_MANAGE = 'cookieManage'

// archive modal
export const I18N_ARCHIVE_MODAL = 'archiveModal'

// map
export const I18N_MAP = 'map'

// legal
export const I18N_LEGAL = 'legal'
export const I18N_LEGAL_MENU = 'legal.menu'
export const I18N_LEGAL_IMPRINT = 'legal.imprint'
export const I18N_LEGAL_TERMS = 'legal.terms'
export const I18N_LEGAL_SAFETY = 'legal.safety'
export const I18N_LEGAL_PRIVACY = 'legal.privacy'
export const I18N_LEGAL_COOKIES = 'legal.cookies'
export const I18N_LEGAL_COOKIES_TYPES = 'legal.cookies.types'

// form
export const I18N_FORM = 'form'
export const I18N_FORM_FILE_FIELD = `${I18N_FORM}.fileField`
export const I18N_FORM_FIELD_NAME = `${I18N_FORM}.fieldName`

// report post modal
export const I18N_REPORT_MODAL = 'reportModal'

// offerCard
const I18N_OFFER_CARD = 'offerCard'
export const I18N_OFFER_CARD_PREVIOUS = `${I18N_OFFER_CARD}.previous`
export const I18N_OFFER_CARD_FAVORITE = `${I18N_OFFER_CARD}.favorite`
export const I18N_OFFER_CARD_FREE = `${I18N_OFFER_CARD}.free`
export const I18N_OFFER_LAST_PUBLICATION = `${I18N_OFFER_CARD}.lastPublication`

// feature unavailable modal
export const I18N_FEATURE_UNAVAILABLE_MODAL = 'featureUnavailableModal'

// about
export const I18N_ABOUT_US = 'about'
export const I18N_ABOUT_US_BENEFITS = `${I18N_ABOUT_US}.benefits`
export const I18N_ABOUT_US_WE_CARE = `${I18N_ABOUT_US}.weCare`
export const I18N_ABOUT_US_MAIN_FEATURES = `${I18N_ABOUT_US}.mainFeatures`
export const I18N_ABOUT_US_HERE_YOU_CAN = `${I18N_ABOUT_US}.hereYouCan`
export const I18N_ABOUT_US_HISTORY = `${I18N_ABOUT_US}.history`
export const I18N_ABOUT_US_MISSION = `${I18N_ABOUT_US}.mission`

// notifications
export const I18N_NOTIFICATIONS = `${I18N_PROFILE}.notifications`
export const I18N_NOTIFICATIONS_EMPTY_STATE = `${I18N_NOTIFICATIONS}.emptyState`

// wallet
export const I18N_WALLET = `${I18N_PROFILE}.wallet`

// requests
export const I18N_REQUESTS = `${I18N_PROFILE}.requests`

// reviews
export const I18N_REVIEWS = `${I18N_PROFILE}.reviews`

export const I18N_STATIC = 'static'
// for business
export const I18N_FOR_BUSINESS = `${I18N_STATIC}.forBusiness`

// advertising
export const I18N_ADVERTISING = `${I18N_STATIC}.advertising`

// business tools
export const I18N_PROFILE_BUSINESS_TOOLS = `${I18N_PROFILE}.businessTools`

// paid  services
export const I18N_PAID_SERVICES = `${I18N_PROFILE}.paidServices`

// coming soon
export const I18N_COMING_SOON = 'emptyState.comingSoon'

// similar offers
export const I18N_SIMILAR_OFFERS = 'similarOffers'

// showcase area (ads)
export const I18N_SHOWCASE_AREA = 'showcaseArea'

// verify phone
export const I18N_VERIFY_PHONE = 'verifyPhone'
