import classNames from 'classnames'
import React, { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import UserAvatar from 'src/components/avatar/UserAvatar'
import { InfoButtonSizes } from 'src/components/button/InfoButton/InfoButton'
import DateComponent from 'src/components/date/DateComponent'
import Heading from 'src/components/heading/Heading'
import { I18N_USER } from 'src/constants/i18n.constants'
import YomeURL from 'src/constants/navigate.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { DateFormatType, dateOptions } from 'src/models/date.model'
import { AvatarSizes } from 'src/models/upload.model'
import { IUser } from 'src/models/user.model'
import BadgeList from 'src/routes/user/info/components/badge/BadgeList'
import { getUserBadges } from 'src/utils/user.utils'

import styles from './styles.module.scss'

interface IPostUserInfo {
  user: IUser;
  isLink?: boolean;
}

const BADGES_NUM = 2

const PostUserInfo: React.FC<IPostUserInfo> = ({ user }) => {
  const { isDesktop } = useViewport()
  const { t } = useTranslation()

  const badges = useMemo(() => getUserBadges(user), [user])

  return (
    <>
      <div>
        {isDesktop && (
          <UserAvatar
            image={user.avatar}
            avatarSize={AvatarSizes.tiny}
            firstLetter={user.name.charAt(0)}
            userId={user.id}
          />
        )}
        <div className={styles.shortUserInfo}>
          <Heading
            label={user.name}
            level='h3'
            className={classNames(isDesktop ? 'label-16' : 'label-14', styles.name) }
          />
          <div className={classNames(styles.registrationDate, 'body-text', 'mobile-hide', 'desktop-body-16')}>
            <span>
              {t(`${I18N_USER}.onYomeSince`)}
            </span>
            <DateComponent
              options={dateOptions.monthYear}
              isoTimestamp={user.createdAt}
              type={DateFormatType.noLabel}
            />
          </div>
          {/* <Grade rating={user.rating} isShort={!isDesktop} /> */}
        </div>
        {!!badges.length && (
          <div className={classNames(styles.badges, 'mobile-hide')}>
            <BadgeList
              badgeList={badges}
              displayedBadgesNum={BADGES_NUM}
              badgeSize={InfoButtonSizes.small}
            />
          </div>
        )}
      </div>
    </>
  )
}

const PostUserInfoWrapper = ({ user, isLink = false }: IPostUserInfo) => {
  if (isLink) {
    return (
      <Link to={YomeURL.userPage(user.id)} >
        <PostUserInfo user={user}/>
      </Link>
    )
  }

  return (
    <div className={styles.userInfoBlock}>
      <PostUserInfo user={user}/>
    </div>
  )
}

export default memo(PostUserInfoWrapper)
