import classNames from 'classnames'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { concatId, IdStructure } from 'src/constants/id.constants'
import { IDropdownActionInternal, TDropdownAction, TDropdownSection } from 'src/models/dropdown.model'

import SvgIcon from '../svgIcon/SvgIcon'

import styles from './dropdown.module.scss'

interface IDropdownSectionProps {
  id: IdStructure;
  section: TDropdownSection;
  selectedAction?: string;
}

interface IDropdownSectionListProps {
  id: IdStructure;
  sections: Record<string, TDropdownSection>;
  className?: string;
  selectedAction?: string;
}

const DropdownActionInternal = memo(({ name, icon, info, isActive, i18noptions }: IDropdownActionInternal) => {
  const { t } = useTranslation()

  return (
    <div className={styles.label}>
      {icon && <SvgIcon name={icon} size={20} className={styles.icon} />}
      <div className={styles.text}>
        <div className='body-text mobile-body-16 desktop-body-16'>
          {t(name, { i18noptions })}
        </div>
        {info && (
          <div className={classNames(styles.info, 'supportive-12')}>
            {t(info)}
          </div>
        )}
      </div>
      {isActive && <SvgIcon name='check-tick' />}
    </div>
  )
})

const DropdownAction = memo(({ id, name, linkTo, onClick, ...other }: TDropdownAction) => {
  const commonProps = {
    id: concatId(id, name),
    className: classNames('bodySizeM', styles.action, { [styles.selected]: other.isActive }),
  }

  const dropdownAction = <DropdownActionInternal name={name} {...other} />

  if (linkTo) {
    return (
      <Link to={linkTo} {...commonProps}>
        {dropdownAction}
      </Link>
    )
  }

  return (
    <button {...commonProps} type='button' onClick={onClick}>
      {dropdownAction}
    </button>
  )
})

const DropdownSection = memo(({ id, section, selectedAction }: IDropdownSectionProps) => (
  <div className={styles.section}>
    {Object.keys(section).map((actionName) => (
      <DropdownAction
        key={actionName}
        id={id}
        name={actionName}
        isActive={actionName === selectedAction}
        {...section[actionName]}
      />
    ))}
  </div>
))

const DropdownSectionList = ({ id, sections, selectedAction, className } : IDropdownSectionListProps) => (
  <div className={classNames(styles.sections, className)}>
    {Object.keys(sections).map((sectionId) => (
      <DropdownSection
        id={id}
        key={sectionId}
        section={sections[sectionId]}
        selectedAction={selectedAction}
      />
    ))}
  </div>
)

export default memo(DropdownSectionList)
