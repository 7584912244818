import { isEqual } from 'lodash-es'
import { memo, useCallback, useEffect, useState } from 'react'
import { useField, useForm } from 'react-final-form'
import usePlacesAutocomplete from 'use-places-autocomplete'

import { I18N_MAP } from 'src/constants/i18n.constants'
import { MAP_AUTOCOMPLETE_REQUEST_OPTIONS } from 'src/constants/map.constants'
import { useMapSearchInput } from 'src/hooks/map.hooks'
import { ILatLng, ILocation } from 'src/models/location.model'
import useGetUserAddressHistory from 'src/services/userAddressHistory.service'
import { getFieldError } from 'src/utils/form.utils'
import { getLocationByAddress, parseLatLngString } from 'src/utils/map.utils'
import {
  LOCATION_ADDRESS_FIELD_NAME,
  LOCATION_POSITION_FIELD_NAME,
} from 'src/yome-categories-fields-config/utils/fieldConfig.utils'

import { IFormMapProps } from '../../models/map.model'
import { FormSearch } from '../form'
import MapComponent from './MapComponent'
import SuggestionList from './SuggestionList'

import mapStyles from './map.module.scss'

// TODO: component now is too large, it's needed to make a refactoring
const FormMap = ({
  onMapError,
  fieldWrapperProps,
  id,
  name,
  placeholder,
  hasOnlyCity,
  selectFirstSuggestionOnBlur,
  ...mapProps
}: IFormMapProps) => {
  const {
    ready,
    setValue,
    suggestions,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: MAP_AUTOCOMPLETE_REQUEST_OPTIONS,
  })

  const { batch, change } = useForm()
  const { input: { onBlur } } = useField(name)
  const [hasMapError, setMapError] = useState(false)
  const [suggestedPosition, setSuggestedPosition] = useState<ILatLng | undefined>()
  const { input: { value: addressValue } } = useField(LOCATION_ADDRESS_FIELD_NAME)
  const { input: { value: positionValue }, meta } = useField(LOCATION_POSITION_FIELD_NAME)
  const { data: userAddressHistory = [] } = useGetUserAddressHistory()

  const changeLocation = useCallback(({ address, position }: ILocation) => {
    batch(() => {
      if (!!address && !isEqual(position, positionValue)) {
        change(LOCATION_POSITION_FIELD_NAME, position)
      }

      if (address !== addressValue) {
        change(LOCATION_ADDRESS_FIELD_NAME, address)
      }
    })
  }, [batch, change, addressValue, positionValue])

  const handleSelect = useCallback((address: string): void => {
    if (address === addressValue) {
      return
    }

    getLocationByAddress(address, hasOnlyCity).then((location) => {
      setSuggestedPosition(location.position)
      changeLocation(location)
      clearSuggestions()
      onBlur()
    })
  }, [clearSuggestions, changeLocation, onBlur, addressValue, hasOnlyCity])

  const handleInputBlur = useCallback(() => {
    const firstSuggestion = suggestions.data[0]

    if (!firstSuggestion) {
      change(LOCATION_POSITION_FIELD_NAME, undefined)
      onBlur()
    } else if (selectFirstSuggestionOnBlur) {
      handleSelect(firstSuggestion.description)
    }

    clearSuggestions()
  }, [change, clearSuggestions, onBlur, handleSelect, suggestions, selectFirstSuggestionOnBlur])

  const { isInputActive, ...searchInputProps } = useMapSearchInput(handleInputBlur)

  useEffect(() => {
    if (!isInputActive) {
      return
    }

    if (!addressValue && userAddressHistory.length) {
      handleSelect(userAddressHistory[0])
    }
  }, [isInputActive])

  useEffect(() => {
    if (!isInputActive) {
      return
    }

    setValue(addressValue)
  }, [setValue, clearSuggestions, isInputActive, addressValue])

  const handleAddressChange = useCallback((location: ILocation) => {
    if (!addressValue) {
      return
    }

    const hasAddress = !!location.address

    setMapError(!hasAddress)
    changeLocation(location)

    if (onMapError) {
      onMapError(!hasAddress)
    }

    onBlur()
  }, [changeLocation, onMapError, onBlur, addressValue])

  return (
    <fieldset className={mapStyles.container}>
      <FormSearch
        {...searchInputProps}
        id={id}
        name={LOCATION_ADDRESS_FIELD_NAME}
        placeholder={placeholder}
        spellCheck={false}
        fieldWrapperProps={{
          ...fieldWrapperProps,
          name: LOCATION_ADDRESS_FIELD_NAME,
          disabled: !ready,
          customError: hasMapError ? `${I18N_MAP}.error` : getFieldError(meta),
        }}
        skipInputBlur
      >
        <SuggestionList
          onSelect={handleSelect}
          suggestions={suggestions}
          userAddressHistory={userAddressHistory}
          inputValue={addressValue.trim()}
        />
      </FormSearch>
      <MapComponent
        {...mapProps}
        center={parseLatLngString(positionValue)}
        skipPositionResolving={suggestedPosition}
        hasMapError={hasMapError}
        hasOnlyCity={hasOnlyCity}
        onAddressChange={handleAddressChange}
      />
    </fieldset>
  )
}

export default memo(FormMap)
