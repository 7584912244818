import { Link } from 'react-router-dom'
import { useViewport } from 'src/contexts/viewportContext'

import styles from './styles.module.scss'

interface IChatHeaderOfferTitle {
  linkToOffer: string;
  title: string;
}

const ChatHeaderOfferTitle: React.FC<IChatHeaderOfferTitle> = ({ linkToOffer, title }) => {
  const { isDesktop } = useViewport()

  return isDesktop
    ? (
      <Link className={styles.offerTitle} to={linkToOffer}>
        {title}
      </Link>
    ) : (
      <span className={styles.offerTitle}>
        {title}
      </span>
    )
}

export default ChatHeaderOfferTitle
