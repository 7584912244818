import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { I18N_MESSENGER } from 'src/constants/i18n.constants'

import styles from './onlineStatus.module.scss'

interface OnlineStatusProps {
  hasOnlineLabel?: boolean;
  isBlocked: boolean;
}

export const OnlineStatus = ({ isBlocked, hasOnlineLabel = false } : OnlineStatusProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.onlineStatus}>
      {!isBlocked && <div className={styles.onlineIcon} />}
      {hasOnlineLabel && (
        <span className={classNames('supportive-14', styles.onlineLabel)}>
          {t(`${I18N_MESSENGER}.onlineLabel`)}
        </span>
      )}
    </div>
  )
}

export default OnlineStatus
