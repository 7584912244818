import classNames from 'classnames'
import React, { ForwardedRef, forwardRef, memo, useCallback } from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { I18N_FORM } from 'src/constants/i18n.constants'
import { concatId, ID } from 'src/constants/id.constants'

import Dropdown from '../../dropdown/Dropdown'
import SvgIcon from '../../svgIcon/SvgIcon'
import FieldWrapper, { IFieldWrapperProps } from '../FieldWrapper'
import FormSelectFullScreen from './FormSelectFullScreen'

import formStyles from '../form.module.scss'
import selectStyles from './select.module.scss'

interface IFormSelectDropdownInternalProps {
  onChange?: (key: string) => void;
  options: Record<string, string>;
  name: string;
  label?: string;
  selectedValue?: string;
  hasIcon?: boolean;
  isFullSize?: boolean;
  disabled?: boolean;
}

interface IFormSelectDropdownProps extends IFormSelectDropdownInternalProps {
  fieldWrapperProps?: IFieldWrapperProps;
}

interface IFormSelectDropdownButtontProps {
  onClick?: () => void;
  name: string;
  iconName?: string;
  currentValue: string;
  hasIcon?: boolean;
  disabled?: boolean;
}

export const FormSelectDropdownButton = forwardRef((
  {
    name,
    hasIcon,
    iconName,
    currentValue,
    onClick,
    disabled,
  }: IFormSelectDropdownButtontProps,
  ref: ForwardedRef<HTMLDivElement>,
) => (
  <div className={classNames(formStyles.inputContainer, { [selectStyles.disabled]: disabled })} ref={ref}>
    <button
      id={concatId(ID.formFieldInputForm, name)}
      className={classNames(formStyles.fieldInput, selectStyles.fieldSelect)}
      onClick={onClick}
      type='button'
      disabled={disabled}
    >
      <span className={selectStyles.currentOption}>
        {hasIcon && iconName && <SvgIcon name={iconName} />}
        <span className={selectStyles.currentOptionText}>
          { currentValue }
        </span>
      </span>
      <div className={selectStyles.chevron}>
        <SvgIcon name='chevron-down' size={24}/>
      </div>
    </button>
  </div>
))

const FormSelectDropdownInternal = ({
  onChange,
  options,
  name,
  label,
  selectedValue,
  hasIcon,
  disabled,
  isFullSize = false,
}: IFormSelectDropdownInternalProps) => {
  const { change } = useForm()
  const { t } = useTranslation()
  const { input: { value, onBlur: onInputBlur } } = useField(name)
  const actualValue = selectedValue || value

  const handleChange = React.useCallback((_: React.MouseEvent, key: string) => {
    if (disabled) {
      return
    }

    if (onChange) {
      onChange(key)
    }

    change(name, key)
    onInputBlur()
  }, [onChange, change, onInputBlur, name, disabled])

  const keys = Object.keys(options)

  const selectActions = Object.fromEntries(
    keys.map((key) => (
      [options[key], {
        onClick: (event: React.MouseEvent) => handleChange(event, key),
        ...(hasIcon && { icon: `${name}/${key}` }),
      }]
    )),
  )

  const displaySelectedElement = useCallback((currentValue: string | undefined) => (
    <FormSelectDropdownButton
      name={name}
      disabled={disabled}
      iconName={`${name}/${currentValue}`}
      currentValue={(currentValue && options[currentValue]) || t(`${I18N_FORM}.select`)}
      hasIcon={hasIcon && !!currentValue && !!selectActions[options[currentValue]]?.icon}
    />
  ), [name, hasIcon, options, disabled, selectActions, t])

  return isFullSize ? (
    <FormSelectFullScreen
      name={name}
      label={label}
      options={options}
      hasIcon={hasIcon}
      selectedValue={actualValue}
      handleChange={handleChange}
    >
      {displaySelectedElement(actualValue)}
    </FormSelectFullScreen>
  ) : (
    <Dropdown
      id={ID.formFieldSelectForm}
      sections={{ section: selectActions }}
      selectedAction={actualValue && options[actualValue]}
      disabled={disabled}
    >
      {displaySelectedElement(actualValue)}
    </Dropdown>
  )
}

const FormSelectDropdown = ({
  name,
  fieldWrapperProps,
  ...other
}: IFormSelectDropdownProps) => (
  <Field name={name}>
    {function renderFormSelectDropdown() {
      return (
        <FieldWrapper {...fieldWrapperProps} name={name} className={selectStyles.fieldSelectContainer}>
          <FormSelectDropdownInternal {...other} name={name} />
        </FieldWrapper>
      )
    }}
  </Field>
)

export default memo(FormSelectDropdown)
