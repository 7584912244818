import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import { useViewport } from 'src/contexts/viewportContext'

import styles from './styles.module.scss'

interface IChatHeaderContentWrapper {
  children: ReactNode;
  linkToOffer: string;
}

const ChatHeaderContentWrapper: React.FC<IChatHeaderContentWrapper> = ({ children, linkToOffer }) => {
  const { isDesktop } = useViewport()

  return isDesktop
    ? (
      <div className={styles.itemInfoWrap}>
        {children}
      </div>
    ) : (
      <Link to={linkToOffer} className={styles.itemInfoWrap}>
        {children}
      </Link>
    )
}

export default ChatHeaderContentWrapper
