import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormButton, FormRadio, FormTextArea } from 'src/components/form'
import { I18N_GLOBAL, I18N_REPORT_MODAL } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { useViewport } from 'src/contexts/viewportContext'
import { reportPost } from 'src/services/report.service'
import { captureErrorAndShowToast, SubmitFormError } from 'src/utils/error.utils'
import { useToggle } from 'src/utils/hooks.utils'

import InfoModal from '../infoModal/InfoModal'
import FullscreenModalLayout from '../mobileModals/FullscreenModalLayout'
import ModalLayout from '../ModalLayout'

import styles from './reportModal.module.scss'

interface IReportModal {
  handleClose: () => void;
  postId: string;
}

interface IReportFormValues {
  reason: string;
  comment: string;
}

const REPORT_REASON_MAP = {
  itemNonAvailable: `${I18N_REPORT_MODAL}.itemNonAvailable`,
  wrongPrice: `${I18N_REPORT_MODAL}.wrongPrice`,
  restrictedItem: `${I18N_REPORT_MODAL}.restrictedItem`,
  violateRules: `${I18N_REPORT_MODAL}.violateRules`,
  wrongItemInfo: `${I18N_REPORT_MODAL}.wrongItemInfo`,
  wrongAdress: `${I18N_REPORT_MODAL}.wrongAdress`,
  scam: `${I18N_REPORT_MODAL}.scam`,
}

const ReportForm = ({ handleClose }: Partial<IReportModal>) => {
  const footerButtons = (
    <div className={styles.buttonRow}>
      <FormButton
        id={ID.reportModalSubmitButton}
        label={`${I18N_REPORT_MODAL}.submitButton`}
        buttonType={RegularButtonTypes.blackNoBorder}
      />
      <RegularButton
        id={ID.reportModalCancelButton}
        label={`${I18N_GLOBAL}.cancel`}
        buttonType={RegularButtonTypes.transparentWithBorder}
        onClick={handleClose}
      />
    </div>
  )

  return (
    <InfoModal
      title={`${I18N_REPORT_MODAL}.title`}
      modalFooter={footerButtons}
      handleClose={handleClose}
    >
      <FormRadio
        name='reason'
        options={REPORT_REASON_MAP}
        className={styles.formRadio}
        id={ID.reportModalInputReason}
      />
      <FormTextArea
        name='comment'
        id={ID.reportModalInputComment}
        placeholder={`${I18N_REPORT_MODAL}.comment.placeholder`}
        fieldWrapperProps={{
          name: 'comment',
          info: `${I18N_REPORT_MODAL}.comment.info`,
        }}
        autoFocus
      />
    </InfoModal>
  )
}

const SuccessReportSubmit = ({ handleClose }: Partial<IReportModal>) => {
  const { t } = useTranslation()

  const footerButton = (
    <RegularButton
      id={ID.modalCloseButton}
      label={`${I18N_GLOBAL}.gotIt`}
      buttonType={RegularButtonTypes.blackNoBorder}
      onClick={handleClose}
    />
  )

  return (
    <InfoModal
      title={`${I18N_REPORT_MODAL}.success.title`}
      topIcon='check-success'
      modalFooter={footerButton}
      className={styles.successContainer}
    >
      <div className='bodySizeL'>
        <p>
          {t(`${I18N_REPORT_MODAL}.success.p1`)}
        </p>
        <p>
          {t(`${I18N_REPORT_MODAL}.success.p2`)}
        </p>
      </div>
    </InfoModal>
  )
}

const ReportModal = ({ handleClose, postId }: IReportModal) => {
  const [successOpen, toggleSuccessOpen] = useToggle()

  const handleReportPost = async ({ reason, comment }: IReportFormValues) => {
    try {
      await reportPost(postId, reason, comment)
      toggleSuccessOpen()
    } catch (error) {
      if (error instanceof SubmitFormError) {
        return error.errorObject
      }

      captureErrorAndShowToast(error)
    }

    return null
  }

  const { isDesktop } = useViewport()

  if (successOpen) {
    return (
      <ModalLayout handleClose={handleClose}>
        <SuccessReportSubmit handleClose={handleClose}/>
      </ModalLayout>
    )
  }

  const Wrapper = isDesktop ? ModalLayout : FullscreenModalLayout

  return (
    <Wrapper handleClose={handleClose}>
      <Form
        onSubmit={handleReportPost}
        render={function RenderReportModalForm({ handleSubmit }) {
          return (
            <form onSubmit={handleSubmit} className={styles.modalContainer}>
              <ReportForm handleClose={handleClose}/>
            </form>
          )
        }}
      />
    </Wrapper>
  )
}

export default ReportModal
