import { ILatLng, ILocation, IOfferLocation } from './location.model'
import { OfferStatus } from './offerStatus.model'
import { IUploadImageResponse } from './upload.model'
import { IUser } from './user.model'

export interface IOfferDraftData {
  slug: string;
  publicId: string;
  status: OfferStatus;
  isAutoRenewal: boolean;

  category: string;
  categoryPath: string[];
  title?: string;
  description?: string;
  photos?: IUploadImageResponse[];
  location?: ILocation;
  // phone: string;
  // communication: string;
  price?: number;

  // goods
  condition?: string;
  negotiable?: boolean;
  color?: string;

  // services
  rate?: string;
  experience?: string;
  place?: string;
  urgent?: string;
}

export interface IOfferData extends IOfferDraftData {
  title: string;
  description: string;
  photos: IUploadImageResponse[];
  location: ILocation;
  translatedFrom?: string;
}

export interface PostFormFields {
  slug: string;
  publicId: string;
  status: OfferStatus;
  isAutoRenewal: boolean;

  category: string;
  categoryPath: string[];
  categoryPathSub0?: string;
  categoryPathSub1?: string;
  categoryPathSub2?: string;
  categoryPathSub3?: string;
  categoryPathSub4?: string;
  title?: string;
  description?: string;
  photos?: IUploadImageResponse[];
  locationAddress?: string;
  locationPosition?: ILatLng;
  // phone?: string;
  // communication?: string;
  price?: string;
  priceInput?: string;

  // goods
  condition?: string;
  negotiable?: boolean;
  color?: string;

  // services
  rate?: string;
  experience?: string;
  place?: string;
  urgent?: string;
}

export type PostFormFieldKeys = keyof PostFormFields

export const DESCRIPTION_FIELD_NAME = 'description'
export const CATEGORY_PATH_FIELD_NAME = 'categoryPath'

export interface IUserPostList {
  active: SinglePost[];
  inactive: SinglePost[];
}

export interface FieldErrorStatus extends Omit<PostFormFields, 'publicId' | 'status'> { }

// TODO: should be synced with backend model
export interface SinglePost extends IOfferData {
  _id: string;
  likes?: number;
  isCreatedByCurrentUser?: boolean;
  hasDelivery: boolean;
  isFavorite: boolean;
  isVisited: boolean;
  isHide?: boolean;
  isAutoRenewal: boolean;
  phone: string;
  user: IUser;
  statistic: IPostStatistic;
  location: IOfferLocation;
  price: number;

  createdAt: string;
  publishedAt?: string;
  closedAt?: string;
  expiredAt: string;
  blockedAt?: string;
  deletedAt: string;
}

export interface IPropsWithOffer {
  offer: SinglePost;
}

export interface ArchivedSinglePost extends SinglePost {
  archiveReason?: string;
}

export interface IMyOffersCounts {
  active: number;
  inactive: number;
  draft: number;
  archive: number;
}

export interface IPostStatistic {
  likesNumber: number;
  viewsNumber: number;
  connectionsNumber: number;
}

export type DraftCreatePayload = {
  categoryPath: string[];
}

export type DraftCreateRes = {
  slug: string;
}

// returns errors
export type DraftUpdateResponse = Record<PostFormFieldKeys, string>
export type DraftValidateResponse = Partial<Record<keyof PostFormFields, string | Record<string, string>>> | undefined

export enum PostListType {
  active = 'active',
  inactive = 'inactive',
  archive = 'archive',
  draft = 'draft',
}

export enum PostDate {
  closedAt = 'closedAt',
  publishedAt = 'publishedAt',
  expiredAt = 'expiredAt',
  createdAt = 'createdAt',
  blockedAt = 'blockedAt',
  deletedAt = 'deletedAt',
}

export interface IOfferList<T> {
  items: T;
}

export interface ILimitedOfferList<T> extends IOfferList<T> {
  hasMore: boolean;
}

export interface ILimitedSingleOfferList extends ILimitedOfferList<SinglePost[]> { }

export interface IAddFavoriteResponse {
  slug: string;
  isFavorite: boolean;
}

export interface IOfferCardProps extends IPropsWithOffer {
  handleLike: (offer: SinglePost) => Promise<void>;
}

export enum CardInfo {
  statistic = 'statistic',
  location = 'location',
}

export interface IPostFeatureData {
  icon: string;
  labelData?: { city: string };
  hint?: { deliveryDays: number; deliveryPrice: number };
}

export enum PostFeatures {
  Delivery = 'delivery',
  Refund = 'refund',
  PaymentUponReceipt = 'paymentUponReceipt',
}

export interface IFavoriteActionParams {
  slug: string;
  method: 'add' | 'remove';
}

export interface OfferListProps {
  offerListEndpoint: string;
}

export interface IStackBoundaryProps {
  offerLimit: string;
}

export interface IStackProps extends Partial<IStackBoundaryProps> {
  stackEndpoint: string;
}

export interface IStackOfferProps extends IStackProps {
  stackInfo: ILimitedSingleOfferList;
}

export const DateStatusMap: Record<OfferStatus, PostDate> = {
  [OfferStatus.closed]: PostDate.publishedAt,
  [OfferStatus.active]: PostDate.publishedAt,
  [OfferStatus.expired]: PostDate.publishedAt,

  [OfferStatus.draft]: PostDate.createdAt,
  [OfferStatus.moderating]: PostDate.createdAt,
  [OfferStatus.declined]: PostDate.createdAt,

  [OfferStatus.deleted]: PostDate.deletedAt,
  [OfferStatus.blocked]: PostDate.blockedAt,
}

export interface IOfferOriginalText {
  title: string;
  description: string;
}
