import classNames from 'classnames'
import { useContext } from 'react'
import { Link } from 'react-router-dom'

import YomeURL from 'src/constants/navigate.constants'
import { useFavorite } from 'src/hooks/favorite.hooks'
import { addMockUserData } from 'src/utils/mock.utils'
import { checkIsInactiveView } from 'src/utils/post.utils'

import { generateIdWithPrefix, PrefixAction } from '../../constants/id.constants'
import PostListContext from '../../contexts/postList.context'
import { IPropsWithOffer } from '../../models/post.model'
import FavoriteRoundedControls from '../roundedControls/FavoriteRoundedControl'
import OfferSpecs from './OfferSpecs/OfferSpecs'
import PostImage from './PostImage/PostImage'
import PostUserInfo from './PostUserInfo/PostUserInfo'

import styles from './postList.module.scss'

const OfferCardHorizontal = ({ offer }: IPropsWithOffer) => {
  const { idPostPrefix, postListKey, hasAllFavorite } = useContext(PostListContext)
  const postIdLike = generateIdWithPrefix(idPostPrefix, PrefixAction.Like, offer.slug)
  const postId = generateIdWithPrefix(idPostPrefix, PrefixAction.Link, offer.slug)

  const isFavoritePost = hasAllFavorite || offer.isFavorite
  const offerLink = YomeURL.offer(offer.category, offer.slug)
  const { user } = offer

  const { handlePostLike } = useFavorite(
    {
      slug: offer.slug,
      isFavoritePost,
      offerSourceEndpoint: postListKey,
    },
  )

  addMockUserData(user)

  const inactiveView = checkIsInactiveView(offer)

  return (
    <div className={classNames(styles.item, styles.itemHorizontal)}>
      {(inactiveView || !offer.isCreatedByCurrentUser) && (
        <div className={styles.addFavoriteButton}>
          <FavoriteRoundedControls
            handleFavoriteClick={handlePostLike}
            isActive={isFavoritePost}
            id={postIdLike}
          />
        </div>
      )}
      <Link to={offerLink} id={postId} className={styles.offerCardLink} >
        <div className={styles.imageWithHeart}>
          <PostImage offer={offer} />
        </div>
        <OfferSpecs offer={offer} isHorizontal user={user} />
      </Link>
      <div className={classNames(styles.userInfo, 'mobile-hide')}>
        <PostUserInfo user={user} isLink/>
      </div>
    </div>

  )
}

export default OfferCardHorizontal
