import { useCallback } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'

import RegularButton, { RegularButtonSizes, RegularButtonTypes } from 'src/components/button/RegularButton'
import { ID } from 'src/constants/id.constants'
import YomeURL, { YomeHash } from 'src/constants/navigate.constants'
import { I18N_POST } from 'src/constants/i18n.constants'
import { useCurrentUser } from 'src/services/currentUser.service'
import { CHAT_ID_SEARCH_PARAM, openOrCreateChatByPost } from 'src/services/messenger.service'
import { captureErrorAndShowToast } from 'src/utils/error.utils'

import styles from './customerActions.module.scss'

interface IWriteMsgBtn {
  postId: string;
  responseTime: number;
  isMobile?: boolean;
}

const WriteMsgBtn = ({ postId, isMobile = false }: IWriteMsgBtn) => {
  const navigate = useNavigate()

  const { data: currentUser } = useCurrentUser()

  const openChat = useCallback(async () => {
    if (!currentUser) {
      navigate(YomeHash.auth)
    }

    try {
      const chatId = await openOrCreateChatByPost(postId)

      navigate({
        pathname: YomeURL.messages,
        search: createSearchParams({ [CHAT_ID_SEARCH_PARAM]: chatId }).toString(),
      })
    } catch (error) {
      captureErrorAndShowToast(error)
    }
  }, [navigate, postId, currentUser])

  return (
    <div className={styles.writeMsgButton}>
      <RegularButton
        id={ID.postPageLinkToMessenger}
        buttonType={RegularButtonTypes.main}
        onClick={openChat}
        label={`${I18N_POST}.writeMessage`}
        leftIcon={{ name: 'message', size: 24 }}
        size={isMobile ? RegularButtonSizes.medium : RegularButtonSizes.large}
      />
      {/*
      <div className={classNames('supportive-14', styles.answerTime)}>
        { t(`${I18N_POST}.user.responseWaitingTime`, { minutes: responseTime })}
      </div>
      */}
    </div>
  )
}

export default WriteMsgBtn
