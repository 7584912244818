import useSWRImmutable from 'swr/immutable'

import { ERROR_CODE, POST_DELETED } from 'src/constants/error.constants'
import { I18N_POST_ACTION } from 'src/constants/i18n.constants'
import YomeAPI from 'src/constants/network.constants'
import SWRkeys from 'src/constants/swr.constants'
import i18n from 'src/i18n'
import {
  DraftCreatePayload,
  DraftCreateRes,
  DraftUpdateResponse,
  FieldErrorStatus,
  IOfferOriginalText,
  PostFormFields,
  SinglePost,
} from 'src/models/post.model'
import { captureErrorAndShowToast, PostDeletedError } from 'src/utils/error.utils'
import { sendDelete, sendFormPost, sendFormPut, sendGet, sendPost } from 'src/utils/network.utils'
import { cleanOfferFormData, prepareFormOfferData } from 'src/utils/postForm.utils'

export const MOBILE_CARD_STACK_LIMIT = '2'
export const DESKTOP_CARD_STACK_LIMIT = '3'
export const SIMILAR_OFFERS_LIMIT = '8'

const publishPost = (
  data: PostFormFields,
) => sendFormPost(YomeAPI.publishOffer(data.slug), cleanOfferFormData(data))

const republishPost = (
  slug: string,
) => sendPost(YomeAPI.republishOffer(slug))

const archivePost = (slug : string, reason: string): Promise<Record<string, string>> => (
  sendFormPost(YomeAPI.archiveOffer, { slug, reason })
)

const deletePost = (slug: string): Promise<void> => sendDelete(YomeAPI.deleteOffer(slug))

const getOffer = async (url: string) => {
  const response = await sendGet(url)

  if (response[ERROR_CODE] === POST_DELETED) {
    throw new PostDeletedError(url)
  }

  return response
}

const useGetOffer = (url: string) => useSWRImmutable<SinglePost>(
  url,
  getOffer,
  { revalidateOnMount: true },
)

const useGetOfferOriginalText = (slug: string) => useSWRImmutable<IOfferOriginalText>(
  SWRkeys.getOfferOriginalText(slug),
  sendGet,
  { revalidateOnMount: true },
)

const createDraft = (
  { categoryPath }: DraftCreatePayload,
): Promise<DraftCreateRes> => sendPost(YomeAPI.createDraft, { categoryPath })

const updateDraft = (
  data: PostFormFields,
): Promise<DraftUpdateResponse> => sendFormPost(YomeAPI.updateDraft, cleanOfferFormData(data, undefined, true))

const validateOffer = (
  data: PostFormFields,
  partIndex: number,
  slug: string,
): Promise<DraftUpdateResponse> => sendFormPost(
  YomeAPI.validateOffer(slug, partIndex),
  cleanOfferFormData(data, partIndex),
)

const getDraftData = async (url: string) => {
  const offerData = await sendGet(url)

  return prepareFormOfferData(offerData)
}

const useGetDraft = (url: string) => (useSWRImmutable<PostFormFields>(url, getDraftData, { revalidateOnMount: true }))

const handleDeletePost = async (post: SinglePost, navigateFunc: () => void) => {
  const { t } = i18n
  const label = t(`${I18N_POST_ACTION}.deleteQuestion`)

  // TODO: make a modal instead of confirm
  // eslint-disable-next-line no-restricted-globals, no-alert
  if (confirm(`${label} "${post.title}?"`)) {
    try {
      await deletePost(post.slug)
      navigateFunc()
    } catch (error) {
      captureErrorAndShowToast(error)
    }
  }
}

const getPostList = <T>(key: string): Promise<T> => sendGet(key)

const useGetPostList = <T>(key: string) => useSWRImmutable<T>(
  key,
  getPostList,
  {
    revalidateOnMount: true,
    keepPreviousData: true,
  },
)

const updateOffer = (
  data: PostFormFields,
  slug: string,
): Promise<FieldErrorStatus> => sendFormPut(YomeAPI.updateOffer(slug), cleanOfferFormData(data))

const getFormOfferData = async (url: string): Promise<PostFormFields> => {
  const offerData = await getOffer(url)

  return prepareFormOfferData(offerData)
}

const useFormOfferData = (url: string) => useSWRImmutable(
  url,
  getFormOfferData,
  { revalidateOnMount: true },
)

interface IAutoRenewalStatus {
  isAutoRenewal: boolean;
}

const setAutoRenewal = (
  slug: string,
  data: IAutoRenewalStatus,
): Promise<IAutoRenewalStatus> => sendPost(YomeAPI.setAutoRenewal(slug), data)

export {
  archivePost,
  createDraft,
  deletePost,
  handleDeletePost,
  publishPost,
  republishPost,
  updateDraft,
  updateOffer,
  useGetDraft,
  useGetOffer,
  useGetPostList,
  useFormOfferData,
  setAutoRenewal,
  validateOffer,
  getOffer,
  useGetOfferOriginalText,
}
