import { useCallback, useEffect, useRef } from 'react'
import { Form } from 'react-final-form'

import RegularButton, { RegularButtonTypes } from 'src/components/button/RegularButton'
import { FormSearch } from 'src/components/form'
import FullscreenModalLayout from 'src/components/modal/mobileModals/FullscreenModalLayout'
import { I18N_GLOBAL, I18N_SEARCH } from 'src/constants/i18n.constants'
import { ID } from 'src/constants/id.constants'
import { CATEGORY_QPARAM, SEARCH_CONTENT_QPARAM } from 'src/models/search.model'
import { ANY_CATEGORY } from 'src/yome-categories-fields-config/category-config/categoriesTree'

import SearchSuggestionsDropdown from '../SearchSuggestionsDropdown/SearchSuggestionsDropdown'

import styles from './mobileSearchInput.module.scss'

interface IFullScreenSearchInputProps {
  close: () => void;
  submit: () => void;
  change: (name: string, value: string) => void;
  initialInputValue: string;
  innitialCategoryValue?: string;
}

const FullScreenSearchInput = ({
  close,
  change,
  submit,
  initialInputValue,
  innitialCategoryValue,
}: IFullScreenSearchInputProps) => {
  const onDummySubmit = useCallback((values: Record<string, string>) => {
    change(SEARCH_CONTENT_QPARAM, values[SEARCH_CONTENT_QPARAM] || '')
    change(CATEGORY_QPARAM, values[CATEGORY_QPARAM] || ANY_CATEGORY)
    close()
    submit()
  }, [close, change, submit])

  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  return (
    <FullscreenModalLayout>
      <Form
        initialValues={{ [SEARCH_CONTENT_QPARAM]: initialInputValue, [CATEGORY_QPARAM]: innitialCategoryValue }}
        onSubmit={onDummySubmit}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit} className='mobile-paddings'>
            <div className={styles.dummySearchBarContainer}>
              <FormSearch
                ref={inputRef}
                name={SEARCH_CONTENT_QPARAM}
                id={ID.searchBarFullScreenInputSearch}
                placeholder={`${I18N_SEARCH}.inputPlaceholder`}
                autoFocus
              />
              <RegularButton
                id={ID.searchBarCancelButton}
                label={`${I18N_GLOBAL}.cancel`}
                buttonType={RegularButtonTypes.transparentNoBorderLight}
                onClick={close}
              />
              <SearchSuggestionsDropdown searchInput={values[SEARCH_CONTENT_QPARAM]} />
            </div>
            <button type='submit' hidden/>
          </form>
        )}
      />
    </FullscreenModalLayout>
  )
}

export default FullScreenSearchInput
