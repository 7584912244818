import config from '../config'

const API_VERSION = 'v1'

const BASE_API = config.baseApiUrl

export const API_OFFER_PREFIX = `${BASE_API}/${API_VERSION}/post`
export const API_USER_PREFIX = `${BASE_API}/${API_VERSION}/user`
export const API_DRAFT_PREFIX = `${BASE_API}/${API_VERSION}/draft`
export const API_SEARCH_PREFIX = `${BASE_API}/${API_VERSION}/search`
export const API_HELP_PREFIX = `${BASE_API}/${API_VERSION}/help`
export const API_REPORT_PREFIX = `${BASE_API}/${API_VERSION}/report`
export const API_PROFILE_PREFIX = `${BASE_API}/${API_VERSION}/profile`
export const API_PREFERENCES_PREFIX = `${BASE_API}/${API_VERSION}/user-preferences`
export const API_SLUG_PREFIX = `${BASE_API}/${API_VERSION}/slug`

export const API_VERIFY_PHONE_PREFIX = `${BASE_API}/${API_VERSION}/verify-phone`

export const API_ADDRESS_HISTORY_PREFIX = `${BASE_API}/${API_VERSION}/address-history`

export const AUTH_API = config.authApiUrl

export const API_AUTH_PREFIX = `${AUTH_API}/${API_VERSION}/auth`
export const API_AUTH_USER_PREFIX = `${AUTH_API}/${API_VERSION}/user`

export const IMAGES_API = config.imageApiUrl

export const API_IMAGE_PREFIX = `${IMAGES_API}/${API_VERSION}/image`

export const MESSENGER_API = config.messengerApiUrl

export const API_MESSENGER_CHAT = `${MESSENGER_API}/${API_VERSION}/chat`
