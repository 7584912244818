import React from 'react'
import { useTranslation } from 'react-i18next'

import { I18N_POST_DATA } from '../../constants/i18n.constants'
import RegularButton, { RegularButtonTypes } from '../button/RegularButton'
import FormRadio from '../form/FormRadio/FormRadio'

import styles from './filter.module.scss'

interface IFilterRadioFieldProps {
  id: string;
  title: string;
  name: string;
  options: string[];
  hasButton?: boolean;
  isTwoColumns?: boolean;
  btnLabel?: string;
}

const FilterRadioField: React.FC<IFilterRadioFieldProps> = ({
  options,
  id,
  name,
  btnLabel,
  hasButton,
  isTwoColumns,
}) => {
  const { t } = useTranslation()

  const optionsObj: Record<string, string> = options.reduce((obj, opt) => (
    { ...obj,
      [opt]: t(`${I18N_POST_DATA}.${name}.${opt}`) }
  ), {})

  const optionsBlockClassName = isTwoColumns ? styles.optionsBlockTwoCol : styles.optionsBlockOneCol

  return (
    <div>
      <div className={optionsBlockClassName}>
        <FormRadio
          options={optionsObj}
          id={id}
          name={name}
        />
      </div>
      {hasButton && (
        <RegularButton
          id='notImplementedRadio'
          label={t(btnLabel || 'search.filterPanel.showMore')}
          onClick={() => { console.log('show more') } }
          buttonType={RegularButtonTypes.transparentNoBorderLight}
        />
      )}
    </div>
  )
}

export default FilterRadioField
